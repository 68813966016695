import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';

import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';
import ThemeRoutes from './routes/index';

const App = () => {
  const customization = useSelector((state) => state.customization);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const data = localStorage.getItem('user');
    setUser(data ? JSON.parse(data) : null);
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <ToastContainer />
        <NavigationScroll>
          <ThemeRoutes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
