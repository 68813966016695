/**
 * Color intention that you want to use in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme) {
  const fallbackColors = {
    primaryLight: '#e3f2fd',
    primaryMain: '#2196f3',
    primaryDark: '#1e88e5',
    secondaryLight: '#f1f8e9',
    secondaryMain: '#8bc34a',
    secondaryDark: '#689f38',
    errorLight: '#ef9a9a',
    errorMain: '#f44336',
    errorDark: '#d32f2f',
    warningLight: '#ffe082',
    warningMain: '#ffc107',
    warningDark: '#ffa000',
    successLight: '#a5d6a7',
    successMain: '#4caf50',
    successDark: '#388e3c',
    grey50: '#fafafa',
    grey100: '#f5f5f5',
    darkTextPrimary: '#212121',
    darkTextSecondary: '#757575',
    textDark: '#424242',
    backgroundDefault: '#ffffff',
    darkBackground: '#303030',
    darkPaper: '#424242',
    paper: '#f5f5f5',
  };

  return {
    mode: theme?.customization?.navType || 'light', // Default to 'light' mode if undefined
    common: {
      black: theme.colors?.darkPaper || fallbackColors.darkPaper,
    },
    primary: {
      light: theme.colors?.primaryLight || fallbackColors.primaryLight,
      main: theme.colors?.primaryMain || fallbackColors.primaryMain,
      dark: theme.colors?.primaryDark || fallbackColors.primaryDark,
      200: theme.colors?.primary200 || '#90caf9',
      800: theme.colors?.primary800 || '#1565c0',
    },
    secondary: {
      light: theme.colors?.secondaryLight || fallbackColors.secondaryLight,
      main: theme.colors?.secondaryMain || fallbackColors.secondaryMain,
      dark: theme.colors?.secondaryDark || fallbackColors.secondaryDark,
      200: theme.colors?.secondary200 || '#c5e1a5',
      800: theme.colors?.secondary800 || '#558b2f',
    },
    error: {
      light: theme.colors?.errorLight || fallbackColors.errorLight,
      main: theme.colors?.errorMain || fallbackColors.errorMain,
      dark: theme.colors?.errorDark || fallbackColors.errorDark,
    },
    orange: {
      light: theme.colors?.orangeLight || '#ffcc80',
      main: theme.colors?.orangeMain || '#ff9800',
      dark: theme.colors?.orangeDark || '#e65100',
    },
    warning: {
      light: theme.colors?.warningLight || fallbackColors.warningLight,
      main: theme.colors?.warningMain || fallbackColors.warningMain,
      dark: theme.colors?.warningDark || fallbackColors.warningDark,
    },
    success: {
      light: theme.colors?.successLight || fallbackColors.successLight,
      200: theme.colors?.success200 || '#a5d6a7',
      main: theme.colors?.successMain || fallbackColors.successMain,
      dark: theme.colors?.successDark || fallbackColors.successDark,
    },
    grey: {
      50: theme.colors?.grey50 || fallbackColors.grey50,
      100: theme.colors?.grey100 || fallbackColors.grey100,
      500: theme.darkTextSecondary || fallbackColors.darkTextSecondary,
      600: theme.heading || '#bdbdbd',
      700: theme.darkTextPrimary || fallbackColors.darkTextPrimary,
      900: theme.textDark || fallbackColors.textDark,
    },
    dark: {
      light: theme.colors?.darkTextPrimary || fallbackColors.darkTextPrimary,
      main: theme.colors?.darkLevel1 || '#424242',
      dark: theme.colors?.darkLevel2 || fallbackColors.darkBackground,
      800: theme.colors?.darkBackground || fallbackColors.darkBackground,
      900: theme.colors?.darkPaper || fallbackColors.darkPaper,
    },
    text: {
      primary: theme.darkTextPrimary || fallbackColors.darkTextPrimary,
      secondary: theme.darkTextSecondary || fallbackColors.darkTextSecondary,
      dark: theme.textDark || fallbackColors.textDark,
      hint: theme.colors?.grey100 || fallbackColors.grey100,
    },
    background: {
      paper: theme.paper || fallbackColors.paper,
      default: theme.backgroundDefault || fallbackColors.backgroundDefault,
    },
  };
}
