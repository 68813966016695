// assets
import { IconKey, IconGasStation, IconDropletHalf2Filled, IconClipboardTypography, IconPigMoney, IconReport } from '@tabler/icons-react';

// constant
const icons = {
  IconKey,
  IconGasStation,
  IconDropletHalf2Filled,
  IconClipboardTypography,
  IconPigMoney,
  IconReport
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const managements = {
  id: 'fuel-management',
  title: 'Fuel Managements',
  type: 'group',
  children: [
    {
      id: 'fuel',
      title: 'Carburants',
      type: 'item',
      url: '/management/fuel', // Fixed the URL to match MainRoutes.js
      icon: icons.IconGasStation
    },
    {
      id: 'pumps',
      title: 'Pomps',
      type: 'item',
      url: '/management/pump', // Fixed the URL to match MainRoutes.js
      icon: icons.IconDropletHalf2Filled
    },
    {
      id: 'assign-duty',
      title: 'Shift',
      type: 'item',
      url: '/management/assign-duty', // Fixed the URL to match MainRoutes.js
      icon: icons.IconClipboardTypography
    },
    {
      id: 'saving',
      title: 'Compte cash/bank',
      type: 'item',
      url: '/management/savings', // Fixed the URL to match MainRoutes.js
      icon: icons.IconPigMoney
    },
    {
      id: 'sales-report',
      title: 'Rapports',
      type: 'item',
      url: '/management/sales', // Fixed the URL to match MainRoutes.js
      icon: icons.IconReport
    }
  ]
};

export default managements;
