import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Lazy-loaded components
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const FuelManagement = Loadable(lazy(() => import('views/Fuel')));
const ViewOrderHistory = Loadable(lazy(() => import('views/Fuel/OverOrderHistory')));
const PumpManagement = Loadable(lazy(() => import('views/Pump')));
const AssignDutyManagement = Loadable(lazy(() => import('views/AssginDuty')));
const DutyManagement = Loadable(lazy(() => import('views/AssginDuty/ViewDuty')));
const SavingManagement = Loadable(lazy(() => import('views/Saving')));
const StaffManagement = Loadable(lazy(() => import('views/Staff')));
const PayrollManagement = Loadable(lazy(() => import('views/Payroll')));
const ViewPayrollManagement = Loadable(lazy(() => import('views/Payroll/ViewPayroll')));
const SupplierManagement = Loadable(lazy(() => import('views/Supplier')));
const ViewSupplierDetails = Loadable(lazy(() => import('views/Supplier/ViewSupplier')));
const CreditorsDetails = Loadable(lazy(() => import('views/Creditors')));
const CreditorsHistory = Loadable(lazy(() => import('views/Creditors/ViewCreditor')));
const SalesManagement = Loadable(lazy(() => import('views/SalesSupport')));
const SettingDetails = Loadable(lazy(() => import('views/Setting')));

// Main routes configuration
const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    // Dashboard
    { index: true, element: <Navigate to="dashboard/default" replace /> },
    { path: 'dashboard/default', element: <DashboardDefault /> },

    // Explicitly defining management routes
    { path: 'management/fuel', element: <FuelManagement /> },
    { path: 'management/pump', element: <PumpManagement /> },
    { path: 'management/assign-duty', element: <AssignDutyManagement /> },
    { path: 'management/order-duty', element: <DutyManagement /> },
    { path: 'management/savings', element: <SavingManagement /> },
    { path: 'management/sales', element: <SalesManagement /> },

    // Order routes
    { path: 'order/history', element: <ViewOrderHistory /> },

    // HRMS routes
    { path: 'hrms/staff', element: <StaffManagement /> },
    { path: 'hrms/payroll', element: <PayrollManagement /> },
    { path: 'hrms/payroll/view', element: <ViewPayrollManagement /> },
    { path: 'hrms/supplier', element: <SupplierManagement /> },
    { path: 'hrms/supplier/view', element: <ViewSupplierDetails /> },
    { path: 'hrms/creditors', element: <CreditorsDetails /> },
    { path: 'hrms/creditors/history', element: <CreditorsHistory /> },

    // Settings
    { path: 'settings', element: <SettingDetails /> },

    // Catch-all route redirects to the default dashboard
    { path: '*', element: <Navigate to="/dashboard/default" replace /> },
  ],
};

export default MainRoutes;
