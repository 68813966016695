import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';

const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

const AuthenticationRoutes = {
  path: '/pages',
  element: <MinimalLayout />,
  children: [
    { path: 'login/login3', element: <AuthLogin3 /> },
    { path: 'register/register3', element: <AuthRegister3 /> },
    { path: '*', element: <Navigate to="login/login3" replace /> },
  ],
};

export default AuthenticationRoutes;
